const pricingComments = [
  `* Revisions refer to any and all changes that is requested during
one singular in-person meeting; call; email; or online session.`,
  `** A web page refers to any singular section which contains content 
distinct from another section. Examples include: landing page, contact us page,
menu page, pricing page, etc.`,
  `*** A custom icon is an icon that is designed by me for a purpose
of your choosing. When deemed appropriate, a template icon will be used where a custom icon
was not chosen.`,
]

export default pricingComments
