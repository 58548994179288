import React from "react"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import "./side_menu.css"

const SideMenu = ({ showMenu, toggleMenu }) => {
  return (
    <div className={`side-menu-${showMenu ? "show" : "hidden"}`}>
      <div className={`overlay-${showMenu ? "show" : "hidden"}`}>
        <div className={`menu-wrapper-${showMenu ? "show" : "hidden"}`}>
          <div className="header-block" />

          <Scrollspy
            className={"side-menu-grid"}
            componentTag={"div"}
            currentClassName={"is-current"}
            items={[
              "service-page",
              "packages-page",
              "about-page",
              "blog",
              "contact-page",
            ]}
          >
            <div className="side-menu-links-wrapper" onClick={toggleMenu}>
              <Link className="side-menu-links" to="/#service-page">
                Services
              </Link>
            </div>

            <div className="side-menu-links-wrapper" onClick={toggleMenu}>
              <Link className="side-menu-links" to="/#packages-page">
                Packages
              </Link>
            </div>

            <div className="side-menu-links-wrapper" onClick={toggleMenu}>
              <Link className="side-menu-links" to="/#about-page">
                About
              </Link>
            </div>

            <div className="side-menu-links-wrapper" onClick={toggleMenu}>
              <Link className="side-menu-links" to="/blog">
                Blog
              </Link>
            </div>

            <div className="side-menu-links-wrapper" onClick={toggleMenu}>
              <Link className="side-menu-links" to="/#contact-page">
                Contact
              </Link>
            </div>
          </Scrollspy>
        </div>
      </div>
    </div>
  )
}

export default SideMenu
