import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import ReactDOM from "react-dom"
import "./pricing_modal.css"

import closeIconBlack from "../../images/CloseIconBlack.svg"

//Styles
const wrapperAnimation = props => keyframes`
  from{
    height:60%;
    width:86vw;
    max-width:400px;
    top: ${props.top}px;
    left: 25px;
  }
  to{
    height:100%;
    width:100vw;
    top:0;
    left:0;
  }
  `
const ModalWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  background-color: white;
`

//StylesEnd

// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null

const PricingModal = props => {
  const { open, top } = props
  const [scroll, setScroll] = useState(0)
  const [topDisplacement, setTopDisplacement] = useState(0)

  const closeModal = () => {
    const body = document.getElementById("___gatsby")
    body.style.overflow = ""
    body.style.height = ""
    window.scrollTo(0, parseInt(scroll || "0"))
    props.toggleModal()
  }

  useEffect(() => {
    function openModal() {
      const body = document.getElementById("___gatsby")
      setScroll(window.scrollY)
      body.style.overflow = "hidden"
      body.style.height = "100%"
    }
    setTopDisplacement(top - window.scrollY)

    if (open) openModal()
  }, [open])

  if (typeof document !== "undefined") {
    return open
      ? ReactDOM.createPortal(
          <div className="pricing-modal">
            <ModalWrapper top={topDisplacement}>
              <div
                className="close-button"
                onClick={closeModal}
                onTouchEnd={closeModal}
              >
                <img
                  className="close-icon"
                  src={closeIconBlack}
                  alt="close-icon"
                />
              </div>
              {props.children}
            </ModalWrapper>
          </div>,
          portalRoot
        )
      : props.children
  } else {
    return null
  }
}

export default PricingModal
