import React, { useEffect } from "react"
import useWindowDimensions from "../../APIs/window_dimensions"
import "./pricing_deck.css"
import pricing from "../../Markup/Pricing"
import PricingCard from "../PricingCard/PricingCard"
import pricingComments from "../../Markup/PricingComments"

var supportsPassive = false
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true
    },
  })
  window.addEventListener("testPassive", null, opts)
  window.removeEventListener("testPassive", null, opts)
} catch (e) {}

const PricingDeck = props => {
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    function handleClick(e) {
      if (e.target.className === "content-wrapper") {
        props.dispatch({ type: "TOGGLE" })
      }
    }

    function handleEscapeKey(e) {
      const key = e.key
      if (key === "Escape") {
        props.dispatch({ type: "TOGGLE" })
      }
    }
    window.addEventListener(
      "click",
      handleClick,
      supportsPassive ? { passive: true } : false
    )
    window.addEventListener(
      "keydown",
      handleEscapeKey,
      supportsPassive ? { passive: true } : false
    )
    return () => {
      window.removeEventListener(
        "click",
        handleClick,
        supportsPassive ? { passive: true } : false
      )
      window.removeEventListener(
        "keydown",
        handleEscapeKey,
        supportsPassive ? { passive: true } : false
      )
    }
  }, [])

  if (typeof document !== "undefined") {
    return (
      <div className="pricing-deck" style={{ height, width }}>
        <div className="overlay" />
        <div className="content-wrapper" style={{ width }}>
          <div
            className="content"
            style={{
              marginTop: 0.05 * height,
              marginBottom: 0.05 * height,
            }}
          >
            <div className="pricing-deck-wrapper">
              {pricing.map(each => (
                <PricingCard
                  pricing={each}
                  key={each.title}
                  showDeckModal={props.showDeckModal}
                />
              ))}
            </div>
            <div className="pricing-comments">
              {pricingComments.map((comment, i) => (
                <p key={i}>{comment}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default PricingDeck
