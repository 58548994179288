import t1 from "../images/PricingT1.svg"
import t2 from "../images/PricingT2.svg"
import t3 from "../images/PricingT3.svg"

const pricing = [
  {
    title: "essential",
    price: "500",
    image: t3,
    description: [
      { criteria: "Website from scratch", detail: true },
      { criteria: "In-person consultation", detail: true },
      { criteria: "Tailored design", detail: true },
      { criteria: "Mobile friendly", detail: true },
      { criteria: "Contact form", detail: true },
      { criteria: "404 redirect", detail: false },
      { criteria: "Google Maps", detail: false },
      { criteria: "Login System", detail: false },
      { criteria: "Google Performance Test", detail: false },
      { criteria: "Number of revisions*", detail: 3 },
      { criteria: "Number of web pages**", detail: 5 },
      { criteria: "Custom Icons***", detail: 0 },
    ],
  },
  {
    title: "standard",
    price: "2000",
    image: t2,
    description: [
      { criteria: "Website from scratch", detail: true },
      { criteria: "In-person consultation", detail: true },
      { criteria: "Tailored design", detail: true },
      { criteria: "Mobile friendly", detail: true },
      { criteria: "Contact form", detail: true },
      { criteria: "404 redirect", detail: true },
      { criteria: "Google Maps", detail: true },
      { criteria: "Login System", detail: false },
      { criteria: "Google Performance Test", detail: false },
      { criteria: "Number of revisions*", detail: 5 },
      { criteria: "Number of web pages**", detail: 5 },
      { criteria: "Custom Icons***", detail: 5 },
    ],
  },
  {
    title: "premium",
    price: "5000",
    image: t1,
    description: [
      { criteria: "Website from scratch", detail: true },
      { criteria: "In-person consultation", detail: true },
      { criteria: "Tailored design", detail: true },
      { criteria: "Mobile friendly", detail: true },
      { criteria: "Contact form", detail: true },
      { criteria: "404 redirect", detail: true },
      { criteria: "Google Maps", detail: true },
      { criteria: "Login System", detail: true },
      { criteria: "Google Performance Test", detail: true },
      { criteria: "Number of revisions*", detail: 10 },
      { criteria: "Number of web pages**", detail: 10 },
      { criteria: "Custom Icons***", detail: 10 },
    ],
  },
]

export default pricing
