import React, { useState } from "react"

import "./header.css"
import LandingHeader from "./LandingHeader"
import BloggHeader from "./BlogHeader"

export const logoSVG = (
  <svg
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192.17 109.57"
  >
    <g>
      <g>
        <path d="M43.04,38.95c-4.41,7.63-8.81,15.27-13.22,22.9c-4.84,8.38,8.12,15.94,12.95,7.57c4.41-7.63,8.81-15.27,13.22-22.9    C60.82,38.15,47.87,30.58,43.04,38.95L43.04,38.95z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M23.07,95.59c9.65,0,9.67-15,0-15C13.42,80.59,13.4,95.59,23.07,95.59L23.07,95.59z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M95.93,38.5C87.11,53.77,78.3,69.03,69.48,84.3c-4.84,8.38,8.12,15.94,12.95,7.57c8.81-15.27,17.63-30.54,26.44-45.8    C113.71,37.69,100.76,30.13,95.93,38.5L95.93,38.5z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M43.04,46.07c8.81,15.27,17.63,30.54,26.44,45.8c4.83,8.36,17.79,0.81,12.95-7.57C73.62,69.03,64.8,53.77,55.99,38.5    C51.16,30.14,38.2,37.69,43.04,46.07L43.04,46.07z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M95.93,46.07c8.81,15.27,17.63,30.54,26.44,45.8c4.83,8.36,17.79,0.81,12.95-7.57c-8.81-15.27-17.63-30.54-26.44-45.8    C104.05,30.14,91.09,37.69,95.93,46.07L95.93,46.07z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M109.15,21.44c4.74,8.21,9.48,16.42,14.22,24.63c4.83,8.36,17.79,0.81,12.95-7.57c-4.74-8.21-9.48-16.42-14.22-24.63    C117.28,5.52,104.31,13.06,109.15,21.44L109.15,21.44z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M130.32,21.44c4.74,8.21,9.48,16.42,14.22,24.63c4.83,8.36,17.79,0.81,12.95-7.57c-4.74-8.21-9.48-16.42-14.22-24.63    C138.45,5.52,125.48,13.06,130.32,21.44L130.32,21.44z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M151.5,21.44c4.74,8.21,9.48,16.42,14.22,24.63c4.83,8.36,17.79,0.81,12.95-7.57c-4.74-8.21-9.48-16.42-14.22-24.63    C159.62,5.52,146.66,13.06,151.5,21.44L151.5,21.44z" />
      </g>
    </g>
  </svg>
)

const Header = props => {
  const [currentPage, setCurrentPage] = useState("landing")

  const handlePageChange = e => {
    if (e) {
      setCurrentPage(e.id)
    }
  }

  return (
    <nav>
      {currentPage === "blog" ? (
        <BloggHeader {...props} pageChange={handlePageChange} />
      ) : (
        <LandingHeader {...props} pageChange={handlePageChange} />
      )}
    </nav>
  )
}

export default Header
