import React, { useState, useContext, useEffect } from "react"
import PricingModal from "../Modal/PricingModal"

import checkIcon from "../../images/CheckIcon.svg"
import closeIcon from "../../images/CloseIcon.svg"
import expandIcon from "../../images/Expand.svg"

import { DeckModalContext } from "../layout"
import pricingComments from "../../Markup/PricingComments"
import useWindowDimensions from "../../APIs/window_dimensions"

const handleDetail = detail => {
  switch (detail) {
    case true: {
      return <img src={checkIcon} alt="check" />
    }
    case false: {
      return <img src={closeIcon} alt="cross" />
    }
    default:
      return detail
  }
}

const PricingCard = ({ pricing, showDeckModal }) => {
  const [cardClass, setCardClass] = useState("card")
  const [readyModal, setReadyModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { dispatch } = useContext(DeckModalContext)
  const { height, width } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(true)
  const [topDisplacement, setTopDisplacement] = useState(0)

  useEffect(() => {
    if (height >= 720 && width >= 1280) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    const rect = document.getElementById(pricing.title).getBoundingClientRect()
    setTopDisplacement(rect.top)
  }, [height, width])

  const handleTouch = action => e => {
    let newClass = "card"
    switch (action) {
      case "start": {
        newClass = `card pressed`
        setReadyModal(true)
        break
      }
      case "end": {
        readyModal ? setOpenModal(!openModal) : setOpenModal(false)
        break
      }
      case "cancel": {
        setReadyModal(false)
        newClass = "card"
        break
      }
      case "move": {
        setReadyModal(false)
        newClass = "card"
        break
      }
      default:
        return null
    }
    setCardClass(newClass)
  }

  let cardProps = openModal
    ? {}
    : {
        onTouchStart: handleTouch("start"),
        onTouchEnd: handleTouch("end"),
        onTouchCancel: handleTouch("cancel"),
        onTouchMove: handleTouch("move"),
      }

  return (
    <PricingModal
      open={openModal}
      toggleModal={() => setOpenModal(false)}
      top={topDisplacement}
    >
      <div id={pricing.title} {...cardProps} className={cardClass}>
        <div className="pricing-card-content">
          <div
            className="pricing-card-title"
            style={openModal ? { borderRadius: 0 } : {}}
          >
            {pricing.title}
          </div>

          <div className="pricing-card-image-wrapper">
            <img
              className="pricing-card-image"
              src={pricing.image}
              alt="tier-3"
            />
          </div>

          {
            //   <div className="pricing-card-pricing">
            //   <div className="pricing-starting">Starting at</div>
            //   <p className="pricing-number">
            //     <sup className="pricing-dollar-sign">$</sup>
            //     {pricing.price}
            //   </p>
            // </div>
          }

          {openModal || showDeckModal ? (
            <table className="pricing-description-table">
              <tbody>
                {pricing.description.map((item, index) => (
                  <tr key={index}>
                    <th key={item.criteria} className="table-criteria">
                      {item.criteria}
                    </th>
                    <th key={item.detail} className="table-detail">
                      {handleDetail(item.detail)}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              className="pricing-more-info"
              onClick={() =>
                isMobile ? setOpenModal(true) : dispatch({ type: "TOGGLE" })
              }
            >
              click for more info
              <img
                style={{ position: "absolute", top: -5, height: 32, width: 32 }}
                src={expandIcon}
                alt="expand-arrow"
              />
            </div>
          )}
          {openModal ? (
            <article className="pricing-comments">
              {pricingComments.map((comment, i) => (
                <p key={i}>{comment}</p>
              ))}
            </article>
          ) : null}
        </div>
      </div>
    </PricingModal>
  )
}

export default PricingCard
