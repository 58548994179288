import React from "react"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import "./BlogHeader.css"

import { logoSVG } from "./header"

const BloggHeader = ({ showMenu, toggleMenu, pageChange }) => {
  return (
    <div className="blog navBar">
      <div className="navBar-wrapper">
        <Link to="/#">
          <div className="logo">{logoSVG}</div>
        </Link>
        <div className="margin-block" />
        <Scrollspy
          className={"links-grid"}
          componentTag={"div"}
          currentClassName={"is-current"}
          items={[
            "service-page",
            "packages-page",
            "about-page",
            "blog",
            "contact-page",
          ]}
          onUpdate={pageChange}
        >
          <div className="links-wrapper">
            <Link className="links" to="/#service-page">
              Services
            </Link>
          </div>

          <div className="links-wrapper">
            <Link className="links" to="/#packages-page">
              Packages
            </Link>
          </div>

          <div className="links-wrapper">
            <Link className="links" to="/#about-page">
              About
            </Link>
          </div>

          <div className="links-wrapper">
            <Link className="links" to="/blog">
              Blog
            </Link>
          </div>

          <div className="links-wrapper">
            <Link className="links" to="/#contact-page">
              Contact
            </Link>
          </div>
        </Scrollspy>

        <div className="menu-wrapper" onClick={toggleMenu}>
          <div className={showMenu ? "menu-icon open" : "menu-icon"}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BloggHeader
