import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import { logoSVG } from "./header"

var supportsPassive = false
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function() {
      supportsPassive = true
    },
  })
  window.addEventListener("testPassive", null, opts)
  window.removeEventListener("testPassive", null, opts)
} catch (e) {}

const LandingHeader = ({ showMenu, toggleMenu, pageChange }) => {
  const [atTop, setAtTop] = useState(true)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      function position(event) {
        if (window.scrollY === 0) {
          setAtTop(true)
        } else {
          setAtTop(false)
        }
      }

      window.addEventListener(
        "scroll",
        position,
        supportsPassive ? { passive: false } : false
      )
      return () => {
        window.removeEventListener(
          "scroll",
          position,
          supportsPassive ? { passive: false } : false
        )
      }
    }
  }, [])

  return (
    <div
      className="navBar"
      style={
        atTop && !showMenu
          ? { backgroundColor: "transparent", boxShadow: "none" }
          : {}
      }
    >
      <div className="navBar-wrapper">
        <Link to="/#">
          <div className="logo">{logoSVG}</div>
        </Link>
        <div className="margin-block" />
        <Scrollspy
          className={"links-grid"}
          componentTag={"div"}
          currentClassName={"is-current"}
          items={[
            "service-page",
            "packages-page",
            "about-page",
            "blog",
            "contact-page",
          ]}
          onUpdate={pageChange}
        >
          <div className="links-wrapper">
            <Link
              className="links"
              to="/#service-page"
              style={
                atTop
                  ? { backgroundColor: "transparent", boxShadow: "none" }
                  : {}
              }
            >
              Services
            </Link>
          </div>

          <div className="links-wrapper">
            <Link
              className="links"
              to="/#packages-page"
              style={
                atTop
                  ? { backgroundColor: "transparent", boxShadow: "none" }
                  : {}
              }
            >
              Packages
            </Link>
          </div>

          <div className="links-wrapper">
            <Link
              className="links"
              to="/#about-page"
              style={
                atTop
                  ? { backgroundColor: "transparent", boxShadow: "none" }
                  : {}
              }
            >
              About
            </Link>
          </div>

          <div className="links-wrapper">
            <Link
              className="links"
              to="/blog"
              style={
                atTop
                  ? { backgroundColor: "transparent", boxShadow: "none" }
                  : {}
              }
            >
              Blog
            </Link>
          </div>

          <div className="links-wrapper">
            <Link
              className="links"
              to="/#contact-page"
              style={
                atTop
                  ? { backgroundColor: "transparent", boxShadow: "none" }
                  : {}
              }
            >
              Contact
            </Link>
          </div>
        </Scrollspy>

        <div className="menu-wrapper" onClick={toggleMenu}>
          <div className={showMenu ? "menu-icon open" : "menu-icon"}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingHeader
