import React, { useState, useReducer, useEffect } from "react"

import Header from "./Header/header"
import "./layout.css"
import Footer from "./footer"
import SideMenu from "./SideMenu/side_menu"
import PricingDeck from "./Modal/PricingDeck"
import SEO from "./seo"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

export const DeckModalContext = React.createContext(false)

function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE": {
      return !state
    }
    default:
      return state
  }
}

const Layout = props => {
  const [showMenu, setShowMenu] = useState(false)
  const [showDeckModal, dispatch] = useReducer(reducer, false)
  const body = typeof window !== "undefined" ? document.body : null

  useEffect(() => {
    showDeckModal
      ? (body.style.overflow = "hidden")
      : (body.style.overflow = "")
  }, [showDeckModal])

  return typeof window !== "undefined" ? (
    <div id="layout" style={{ overflowX: "hidden" }}>
      <SEO title="MCubed | Professional Web Design and Development" />
      <Header showMenu={showMenu} toggleMenu={() => setShowMenu(!showMenu)} />
      <SideMenu showMenu={showMenu} toggleMenu={() => setShowMenu(false)} />
      {showDeckModal ? (
        <PricingDeck dispatch={dispatch} showDeckModal={showDeckModal} />
      ) : null}
      <DeckModalContext.Provider value={{ showDeckModal, dispatch }}>
        <main id="main">{props.children}</main>
      </DeckModalContext.Provider>
      <Footer />
    </div>
  ) : null
}

export default Layout
